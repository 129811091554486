.tour-not-available {
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: lighter;
  }

  .awaitingsun_logo {
    width: 250px;
  }
}
