.custom_marker {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 64px;
    height: 64px;
    border: 0 solid rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 0;
  }

  img {
    border-radius: 50%;
    background: rgba(255,255,255, 0.7);
    padding: 2px;
    width: 60px;
    height: 60px;
    opacity: 0.8;
  }

  &:hover {
    .inner {
      border: 10px solid rgba(255, 255, 255, 0);
      padding: 20px;
      transition: border 0.5s, padding 0.5s;
    }
  }
}

.panorama_viewer.standalone_viewer .psv-navbar {
  display: none;
}
