.logo-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  z-index: 999;

  img {
    width: 130px;
  }

  .container {
    height: 40px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    margin-left: 10px;
  }
}
