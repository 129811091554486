.primary_header {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 40px;
  z-index: 1000;
  box-shadow: 0 2px 10px -2px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;


}
