.property_list {
  margin-top: 60px;

  .list {
    margin: 0 10px;
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }

  header {
    display: flex;
    justify-content: space-between;
  }
}
