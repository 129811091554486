.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login_card {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    padding: 30px;

    .logo {
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .MuiTextField-root, button {
    margin: 5px;
  }
}
