.welcome {
  background: white;
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;

  .awaitingsun_logo {
    width: 40%;

    @media (max-width: 500px) {
      width: 75%;
    }
  }
}
